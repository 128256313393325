import { gql } from '@apollo/client'

export const servicePaths = gql`
  query Service {
    service {
      title
      updated_at
      metadata: serviceMetadata {
        path
      }
    }
  }
`

export const serviceById = gql`
  query Service($path: String!) {
    content: service(
      order_by: { title: asc }
      where: { serviceMetadata: { path: { _ilike: $path } } }
    ) {
      id
      title
      subtitle
      content
      attributes
      options
      archived
      stripe_id
      createdAt: created_at
      updatedAt: updated_at
      media(order_by: { assetByAsset: { updatedAt: desc } }) {
        id
        asset: assetByAsset {
          id
          url
          type: assetType {
            value: value
          }
          updatedAt
          createdAt
        }
      }
      category: categoryByCategory {
        id
        name
      }
      metadata: serviceMetadata {
        id
        description
        path
        title
        createdAt: created_at
        updatedAt: updated_at
      }
      pricing(
        where: {
          pricingByPricing: {
            _and: [
              { active: { _eq: true } }
              { label: { _neq: "Booking Fee" } }
            ]
          }
        }
        order_by: { pricingByPricing: { price: asc } }
      ) {
        data: pricingByPricing {
          id
          label
          price
          attributes
          discount
          was
          stripe_id
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options(order_by: { pricingByOption: { price: asc } }) {
            data: pricingByOption {
              id
              attributes
              label
              price
              was
              stripe_id
              discount
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
      tags {
        id
        tag: tagByTag {
          name
        }
      }
    }
  }
`

export const servicesById = gql`
  query servicesById($ids: [uuid!]) {
    services: service(where: { id: { _in: $ids } }) {
      id
      title
      subtitle
      content
      attributes
      options
      stripe_id
      createdAt: created_at
      updatedAt: updated_at
      media(
        where: { assetByAsset: { type: { _eq: IMAGE } } }
        order_by: { assetByAsset: { updatedAt: desc } }
      ) {
        id
        asset: assetByAsset {
          id
          url
          type: assetType {
            value: value
          }
          updatedAt
          createdAt
        }
      }
      category: categoryByCategory {
        id
        name
      }
      metadata: serviceMetadata {
        id
        description
        path
        title
        createdAt: created_at
        updatedAt: updated_at
      }
      pricing(
        where: {
          pricingByPricing: {
            _and: [
              { active: { _eq: true } }
              { label: { _neq: "Booking Fee" } }
            ]
          }
        }
        order_by: { pricingByPricing: { price: asc } }
      ) {
        data: pricingByPricing {
          id
          label
          price
          attributes
          discount
          was
          stripe_id
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options {
            data: pricingByOption {
              id
              attributes
              label
              price
              was
              stripe_id
              discount
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
      tags {
        id
        tag: tagByTag {
          name
        }
      }
    }
  }
`

export const services = gql`
  query Service {
    content: service(
      where: {
        archived: { _eq: false }
      }
      order_by: { updated_at: desc }
    ) {
      id
      title
      subtitle
      content
      attributes
      options
      stripe_id
      createdAt: created_at
      updatedAt: updated_at
      media(order_by: { assetByAsset: { updatedAt: desc } }) {
        id
        asset: assetByAsset {
          id
          url
          type: assetType {
            value: value
          }
          updatedAt
          createdAt
        }
      }
      category: categoryByCategory {
        id
        name
      }
      metadata: serviceMetadata {
        id
        description
        path
        title
        createdAt: created_at
        updatedAt: updated_at
      }
      pricing(
        where: {
          pricingByPricing: {
            _and: [
              { active: { _eq: true } }
              { label: { _neq: "Booking Fee" } }
            ]
          }
        }
        order_by: { pricingByPricing: { price: asc } }
      ) {
        data: pricingByPricing {
          id
          active
          label
          price
          attributes
          discount
          was
          stripe_id
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options(
            where: { pricingByOption: { active: { _eq: true } } }
            order_by: { pricingByOption: { price: asc } }
          ) {
            data: pricingByOption {
              id
              attributes
              active
              label
              price
              was
              stripe_id
              discount
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
      tags {
        id
        tag: tagByTag {
          name
        }
      }
    }
  }
`

export const servicesByCategory = gql`
  query ServicesByCategory($category: String!, $exclude: [uuid!]) {
    content: service(
      order_by: { updated_at: desc }
      where: {
        _and: [
          { archived: { _neq: true } }
          { categoryByCategory: { name: { _eq: $category } } }
          { _not: { id: { _in: $exclude } } }
        ]
      }
    ) {
      id
      title
      subtitle
      content
      attributes
      options
      stripe_id
      createdAt: created_at
      updatedAt: updated_at
      media(order_by: { assetByAsset: { updatedAt: desc } }) {
        id
        asset: assetByAsset {
          id
          url
          type: assetType {
            value: value
          }
          updatedAt
          createdAt
        }
      }
      category: categoryByCategory {
        id
        name
      }
      metadata: serviceMetadata {
        id
        description
        path
        title
        createdAt: created_at
        updatedAt: updated_at
      }
      pricing(
        where: {
          pricingByPricing: {
            _and: [
              { active: { _eq: true } }
              { label: { _neq: "Booking Fee" } }
            ]
          }
        }
        order_by: { pricingByPricing: { price: asc } }
      ) {
        data: pricingByPricing {
          id
          label
          price
          attributes
          discount
          was
          stripe_id
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options(
            order_by: { pricingByOption: { price: asc } }
          ) {
            data: pricingByOption {
              id
              attributes
              label
              price
              stripe_id
              was
              discount
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
      tags {
        id
        tag: tagByTag {
          name
        }
      }
    }
  }
`

export const servicesBySearch = gql`
  query ServicesBySearch($search: String!) {
    content: service(
      order_by: { title: asc }
      where: {
        _and: [
          { archived: { _neq: true } }
          {
            _or: [
              { title: { _ilike: $search } }
              { tags: { tagByTag: { name: { _ilike: $search } } } }
            ]
          }
        ]
      }
    ) {
      id
      title
      subtitle
      content
      attributes
      options
      stripe_id
      createdAt: created_at
      updatedAt: updated_at
      media(order_by: { assetByAsset: { updatedAt: desc } }) {
        id
        asset: assetByAsset {
          id
          url
          type: assetType {
            value: value
          }
          updatedAt
          createdAt
        }
      }
      category: categoryByCategory {
        id
        name
      }
      metadata: serviceMetadata {
        id
        description
        path
        title
        createdAt: created_at
        updatedAt: updated_at
      }
      pricing(
        where: {
          pricingByPricing: {
            _and: [
              { active: { _eq: true } }
              { label: { _neq: "Booking Fee" } }
            ]
          }
        }
        order_by: { pricingByPricing: { created_at: desc_nulls_last } }
      ) {
        data: pricingByPricing {
          id
          label
          price
          attributes
          discount
          was
          stripe_id
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options {
            data: pricingByOption {
              id
              attributes
              label
              price
              was
              stripe_id
              discount
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
      tags {
        id
        tag: tagByTag {
          name
        }
      }
    }
  }
`

export const serviceByUuid = gql`
  query Service($id: uuid!) {
    content: service_by_pk(id: $id) {
      id
      title
      subtitle
      content
      attributes
      options
      archived
      stripe_id
      createdAt: created_at
      updatedAt: updated_at
      media(order_by: { assetByAsset: { updatedAt: desc } }) {
        id
        asset: assetByAsset {
          id
          url
          type: assetType {
            value: value
          }
          updatedAt
          createdAt
        }
      }
      category: categoryByCategory {
        id
        name
      }
      metadata: serviceMetadata {
        id
        description
        path
        title
        createdAt: created_at
        updatedAt: updated_at
      }
      pricing(
        where: {
          pricingByPricing: {
            _and: [
              { active: { _eq: true } }
              { label: { _neq: "Booking Fee" } }
            ]
          }
        }
        order_by: { pricingByPricing: { price: asc } }
      ) {
        data: pricingByPricing {
          id
          label
          price
          attributes
          discount
          was
          stripe_id
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options(
            order_by: { pricingByOption: { price: asc } }
          ) {
            data: pricingByOption {
              id
              attributes
              label
              price
              was
              stripe_id
              discount
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
      tags {
        id
        tag: tagByTag {
          name
        }
      }
    }
  }
`

export const popularServices = gql`
  query Popular {
    service(
      limit: 5
      where: { tags: { tagByTag: { name: { _eq: "most-popular" } } } }
    ) {
      id
      title
      subtitle
      content
      attributes
      options
      stripe_id
      createdAt: created_at
      updatedAt: updated_at
      media(order_by: { assetByAsset: { updatedAt: desc } }) {
        id
        asset: assetByAsset {
          id
          url
          type: assetType {
            value: value
          }
          updatedAt
          createdAt
        }
      }
      category: categoryByCategory {
        id
        name
      }
      metadata: serviceMetadata {
        id
        description
        path
        title
        createdAt: created_at
        updatedAt: updated_at
      }
      pricing(
        where: {
          pricingByPricing: {
            _and: [
              { active: { _eq: true } }
              { label: { _neq: "Booking Fee" } }
            ]
          }
        }
        order_by: { pricingByPricing: { price: asc } }
      ) {
        data: pricingByPricing {
          id
          label
          price
          attributes
          discount
          was
          stripe_id
          createdAt: created_at
          updatedAt: updated_at
          type: pricing_type {
            value: type
          }
          options(
            order_by: { pricingByOption: { price: asc } }
          ) {
            data: pricingByOption {
              id
              attributes
              label
              price
              was
              discount
              stripe_id
              createdAt: created_at
              updatedAt: updated_at
            }
          }
        }
      }
      tags {
        id
        tag: tagByTag {
          name
        }
      }
    }
  }
`

export const hsServices = gql`
  query HServices {
    services: resource_service(
      where: {
        resource_id: {
          _in: [
            "b593caa0-7232-4937-81bf-da7b8aa78643"
            "9549cb3d-aacc-4865-aa11-4e2428db33fb"
          ]
        }
      }
    ) {
      id: service_id
    }
  }
`
